import React, {Component} from 'react';
import dynamic from 'next/dynamic';
import HierarchyUtils from '../../adapters/helpers/HierarchyUtils';
import SlideToggle from '../ReactToggleSlider/ReactSlideToggle.umd';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { MainMenuConstants } from '../../adapters/helpers/Constants';
import {Label} from '../Label/Label';
import { stripHtml, stringToCapitalize } from '../../adapters/helpers/Utils';
const LanguageSelection = dynamic(() => import('../../components/LanguageSelection/LanguageSelection'));
const LoginNavigationBlock = dynamic(() => import('../../components/LoginNavigationBlock/LoginNavigationBlock'));
import DOMPurify from 'isomorphic-dompurify';
const Icon = dynamic(() => import('../Icon/Icon'));
const Image = dynamic(() => import('../Image/Image'));

class MobileTabLayout extends Component {
    _closedSubNavState = '';
    constructor(props) {
        super(props);
       this.insideMenuRefMobile = React.createRef();
       this.subMenuRef = React.createRef();
       this.mobileMenuRef = React.createRef();
       this.openMobileMenuRef = React.createRef();
       this.innerMenuRef = React.createRef();
       this.primaryColor = MainMenuConstants.primaryColor;          
       this.trapFocusInOverlay = this.trapFocusInOverlay.bind(this);
        this.state = {
            isMainMenuOpen: false,
            currentSubNav: this._closedSubNavState,
            intervalId: 0,
            lastScroll: 0,
            pathName: '',
            targetElemIdStatus: '',
            prevElementId: '',
            targetInnerElemIdStatus: '',
            prevInnerElementId: '',
            hierarchyMainLogo: this.props?.hierarchy?.fields?.destinations && this.props?.hierarchy?.fields?.destinations[0],
            mainArrowStatus: MainMenuConstants.downArrow,
            innerArrowStatus: MainMenuConstants.plusArrow          
        };

        this.firstLink = null;
        this.lastLink = null;
    }

    trapFocusInOverlay (event) {
        if (!this.state.isMainMenuOpen) {
            return;
        }  
        const element = event.currentTarget;
        const focusableElements = element.querySelectorAll('.header-main-menu button:not([disabled])');
        const lastMobileMainmenuElement = element.querySelectorAll('.event_button_click.ob-language-selector-toggle');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = lastMobileMainmenuElement[0];
        const KEYCODE_TAB = 9;
        const isTabPressed = (event.key === 'Tab' || event.keyCode === KEYCODE_TAB);
        if (!isTabPressed) {
            return;
        }
        if ( event.shiftKey ) /* shift + tab */ {
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus();
                event.preventDefault();
            }
        } else /* tab */ {
            if (document.activeElement === lastFocusableElement) {
                firstFocusableElement.focus();
                event.preventDefault();
            }
        }
    };

    componentDidMount() { 
        window.addEventListener(MainMenuConstants.mousedown, this.handleClickOutside);
        window.addEventListener(MainMenuConstants.resize, this.handleResizeThrottled);
        window.addEventListener(MainMenuConstants.scroll, this.handleScrollThrottled);
        window.addEventListener(MainMenuConstants.keydown, this.handleEscKeyPress);
        this.setState({pathName: window.location.pathname})
        this.handleScroll();
        this.handleResize();   
    }

    componentWillUnmount() {
        window.removeEventListener(MainMenuConstants.mousedown, this.handleClickOutside);
        window.removeEventListener(MainMenuConstants.resize, this.handleResizeThrottled);
        window.removeEventListener(MainMenuConstants.scroll, this.handleScrollThrottled);
        window.removeEventListener(MainMenuConstants.keydown, this.handleEscKeyPress);

        if (this.lastLink) {
            this.lastLink.removeEventListener(MainMenuConstants.blur, this.handleMobileLastItemBlur);
        }
    }

    handleMobileLastItemBlur(event) {
        if (this.firstLink) {
            event.preventDefault();
            event.stopPropagation();
            this.firstLink.focus();
        }
    }

    handleScroll() {
        if (!document.body.classList.contains('noScroll')) {
            this.setState({
                lastScroll: window.pageYOffset
            })
        }
    }

    handleResize() {
        if (this.openMobileMenuRef?.current && this.state.isMainMenuOpen) {
            this.openMobileMenuRef.current.toggle();
        }

        this.setState({
            currentSubNav: this._closedSubNavState,
            isMainMenuOpen: false,
        }, () => {
            this.toggleScroll();
        });
    }

    handleResizeThrottled = throttle(this.handleResize.bind(this), 100);
    
    handleScrollThrottled = throttle(this.handleScroll.bind(this), 100);

    handleClickOutside(event) {
        if (this.insideMenuRefMobile?.current) {
            if (!this.insideMenuRefMobile.current.contains(event.target)) {
                this.setState({currentSubNav: this._closedSubNavState});
            }
        }
    }

    handleEscKeyPress(event) {
        if (event.key === MainMenuConstants.escape) {
            this.setState({
                currentSubNav: this._closedSubNavState,
            }, this.handleAfterEscKeyPress)
        }
    }

    handleAfterEscKeyPress() {
        this.toggleScroll();
        this.addBlurListenerOnLastLink();
    }

    getHierarchyProperties(hierarchy) {
        const destinations = hierarchy.fields.destinations;
        const title = hierarchy.fields.title;
        const hierarchyId = HierarchyUtils.getHierarchyId(hierarchy);
        const isSubNav = hierarchy.fields.type === 'product-by-need' || hierarchy.fields.type === 'product-by-type' || hierarchy.fields.type === 'why-oral-b' || hierarchy.fields.type === 'conditions' || hierarchy.fields.type === 'life-stages'|| hierarchy.fields.type === 'our-ambition';
        const isMainMenu = hierarchyId === MainMenuConstants.hierarchyMainMenu;
        const isSubNavCtn = !isMainMenu && !isSubNav;
        const imgVisible = true;

        let itemClassNames = !isSubNav ? 'ob-main-menu__list--item' : 'ob-sub-navigation--item';
        let mainMenuClassNames = isMainMenu ? 'ob-main-menu' : '';
        let subNavigationListClassNames = isSubNav ? 'ob-sub-navigation__list' : '';
        let iconPosition = null;
        const mainMenuListClassNames = isSubNavCtn ? 'ob-main-menu__subNav--ctn' : '';
        const listClassNames = `ob-main-menu__list ${mainMenuListClassNames}`;
        const onlyShowOnClassNames = hierarchy.fields.onlyShowOn ? `ob-main-menu__list--${hierarchy.fields.onlyShowOn}` : '';

        if (isSubNavCtn) {
            itemClassNames = ' ob-sub-navigation';
            iconPosition = 'after';
        }

        return {
            destinations,
            title,
            hierarchyId,
            isSubNav,
            isMainMenu,
            isSubNavCtn,
            itemClassNames,
            mainMenuClassNames,
            subNavigationListClassNames,
            iconPosition,
            mainMenuListClassNames,
            listClassNames,
            onlyShowOnClassNames,
            imgVisible
        };
    }

    handleToggleMainMenu() {
        if (this.props.isLarge && !this.state.isMainMenuOpen) {
            this.addBlurListenerOnLastLink();
        }

        this.setState({
            isMainMenuOpen: !this.state.isMainMenuOpen,
            currentSubNav: this._closedSubNavState,
        }, this.toggleScroll);
    }

    toggleScroll() {
        if (this.state.isMainMenuOpen || this.state.currentSubNav !== this._closedSubNavState) {
            document.body.classList.add('noScroll');
            document.body.style.top = `-${this.state.lastScroll}px`;
            document.body.style.height = `-${this.state.lastScroll}px`;
        } else {
            document.body.classList.remove('noScroll');
            document.body.style.top = null;
            document.body.style.height = null;
            window.scrollTo(0, this.state.lastScroll);
        }
    }

    addBlurListenerOnLastLink() {
        let mobileMenu = document.querySelector('.ob-main-menu--mobile');
        if (mobileMenu) {
            let links = mobileMenu.getElementsByTagName('a');
            if (links?.length > 0) {
                this.firstLink = links[0];
                this.lastLink = links[links.length-1];
                this.lastLink.addEventListener(MainMenuConstants.blur, this.handleMobileLastItemBlur.bind(this));
            }
        }
    }

    handleMenuHeight() {
        const innerMenu = document.querySelector('.ob-menu-collapsible');
        const announcementBar = document.querySelector('.ob-announcement-bar');
        document.body.classList.add('noScroll');
        if(innerMenu && innerMenu.style) {
            if (announcementBar && announcementBar.style.display !== 'none') {
                innerMenu.style.height = (window.innerHeight - innerMenu.offsetTop + announcementBar.offsetHeight) + 'px'
            } else {
                innerMenu.style.height = (window.innerHeight - innerMenu.offsetTop)+'px';
            }
        }
    }

    renderHierarchyMobile(hierarchy) {
        const destinations = hierarchy.fields.destinations;
        let title = hierarchy.fields.title;
        let {targetElemIdStatus} = this.state;
        let status = targetElemIdStatus !== HierarchyUtils.getHierarchyId(hierarchy) ? false : true;
    
        return (
            <div>
            {title && this.renderHierarchyTitle(hierarchy, title)}
              {destinations && <ul id={HierarchyUtils.getHierarchyId(hierarchy)} style={{display:'none'}} className="ob-mobile-ul-list">
                {
                  destinations.map((d, index) => {
                    d.imgVisible = hierarchy.imgVisible;
                    return <li key={index}>{this.renderDestinationMobile(d)}</li>;
                  })
                }     
                
              </ul>
              }
            </div>
        )
      }

      handleInnerSubNavigation(event) {
        this.setState((prevState) => {prevInnerElementId: prevState.prevInnerElementId});
        const targetElemId = event.currentTarget.dataset.childId;
        let targetElement = document.getElementById(targetElemId);
        let tarStatus = window.getComputedStyle(targetElement).display;
        let targetElementStatus = tarStatus === MainMenuConstants.displayNone ? true : false;
        
        if(targetElement) {
            if(this.state.prevInnerElementId && this.state.prevElement !== targetElemId) {
                let prevElement = document.getElementById(this.state.prevInnerElementId);
                prevElement.style.display = MainMenuConstants.displayNone;
            }

        if(targetElementStatus) {
            targetElement.style.display = MainMenuConstants.displayBlock;
            this.setState({innerArrowStatus: MainMenuConstants.minusArrow});
        } else {
            targetElement.style.display = MainMenuConstants.displayNone;
            this.setState({innerArrowStatus: MainMenuConstants.plusArrow});
        }
        this.setState({currentSubNav: targetElemId});
        this.setState({targetInnerElemIdStatus: targetElemId});
        this.setState({prevInnerElementId: targetElemId});
        }
    }

    handleToggleSubNavigation(event) {
        this.setState((prevState) => {prevElementId: prevState.prevElementId});
        const targetElemId = event.currentTarget.dataset.childId;
        let targetElement = document.getElementById(targetElemId);
        let tarStatus = window.getComputedStyle(targetElement).display;
        let targetElementStatus = tarStatus === MainMenuConstants.displayNone ? true : false;
        if(targetElement) {
            
            if(this.state.prevElementId && this.state.prevElement !== targetElemId) {
                let prevElement = document.getElementById(this.state.prevElementId);
                prevElement.style.display = MainMenuConstants.displayNone;                
            }
            if(targetElementStatus) {
                targetElement.style.display = MainMenuConstants.displayBlock;
                this.setState({mainArrowStatus: MainMenuConstants.upArrow});
            } else {
                targetElement.style.display = MainMenuConstants.displayNone;
                this.setState({mainArrowStatus: MainMenuConstants.downArrow});
            }
            this.setState({targetElemIdStatus: targetElemId});
            this.setState({prevElementId: targetElemId});           
        }        
    }

    renderHierarchyTitle(hierarchy, title, callback = null, toggleState = null) {
        const level = HierarchyUtils.getLevel(hierarchy);
        const childId = HierarchyUtils.getHierarchyId(hierarchy);
        const type = hierarchy.fields.type;

        let classNameArrow = MainMenuConstants.downArrow;
        if(this.state.targetElemIdStatus === childId) {
            classNameArrow = this.state.mainArrowStatus;
        }

        let classNameInnerArrow = MainMenuConstants.plusArrow;
        if(this.state.targetInnerElemIdStatus === childId) {
            classNameInnerArrow = this.state.innerArrowStatus;
        }

        if (level === MainMenuConstants.child
            && type !== MainMenuConstants.productByNeed
            && type !== MainMenuConstants.productByType
            && type !== MainMenuConstants.whyOralB
            && type !== MainMenuConstants.conditions
            && type !== MainMenuConstants.lifeStages
            && type !== MainMenuConstants.ourAmbition  ) {
            return (
                <button
                    className={`event_menu_click ob-main-menu__subNav--btn link-button${this.state.currentSubNav === childId || this.checkIfActive(hierarchy?.fields?.destinations) ? ' is-active' : ''}`}
                    data-action-detail={`${MainMenuConstants.Open} ${stripHtml(title)} ${MainMenuConstants.panel}`}
                    aria-label={`${stringToCapitalize(stripHtml(title))} ${MainMenuConstants.pullDown} ${this.state.currentSubNav === childId ? MainMenuConstants.expanded : MainMenuConstants.collapsed}`}
                    data-child-id={childId}
                    onClick={(event) => {
                        this.handleToggleSubNavigation(event)
                    }}
                >
                    {this.props.isLarge && hierarchy.fields.mainAsset ? (
                        <span className='ob-main-menu__subNav--btn-ctn'>
                            <span className='ob-main-menu__subNav--btn-img'>
                                <Image image={hierarchy.fields.mainAsset} />
                            </span>
                            <div className='ob-main-menu__subNav--btn-content'>
                                <span className='ob-main-menu__subNav--btn-title' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title)}}></span>
                                <span className='ob-main-menu__subNav--btn-icon'>
                                    <Icon name='chevronRight' size='1.3' color={this.primaryColor} />
                                </span>
                            </div>
                        </span>
                    ) : (
                        <span>
                            {this.state.currentSubNav === childId || this.checkIfActive(hierarchy?.fields?.destinations) ? 
                               <span aria-current={'page'} className='ob-main-menu__subNav--btn-title' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title)}}></span> :
                               <span className='ob-main-menu__subNav--btn-title' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title)}}></span>
                            }
                            <span className={`ob-main-menu__subNav--btn-icon ${classNameArrow}`}>
                            <Icon name='chevronDown' size='1.3' color="#3D3D41" />
                            </span>
                        </span>
                    )
                    }
                </button>
            );
        } else {
            return (
                <div>
                    <p className='ob-sub-navigation__title ob-sub-navigation__title--desktop' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title)}}></p>
                    <button aria-expanded={classNameInnerArrow === MainMenuConstants.minusArrow ? true : false}
                    onClick={(event) => this.handleInnerSubNavigation(event)} className='ob-sub-navigation__title ob-sub-navigation__title--mobile'
                    data-child-id={childId}
                    >
                        <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(HierarchyUtils.toFirstUpperCase(title, true))}}></span>
                        {classNameInnerArrow === MainMenuConstants.minusArrow ? <Icon name={MainMenuConstants.minus6} viewBox={10} size='1' color='#000000'  /> :
                        <Icon name={MainMenuConstants.plus} viewBox={10} size='1' color='#000000'  />}
                    </button>
                </div>
            );
        }
    }

    checkIfActive(menuItem) {
        let isActive = false;
        if (this.state.pathName !== '') {
            if (Array.isArray(menuItem)) {
                menuItem.forEach((destination) => {
                    if (destination?.fields?.pagePath) {
                        if (destination?.fields?.pagePath?.fields?.slug === this.state.pathName) {
                            isActive = true;
                        }
                    } else if (destination?.fields?.destinations) {
                        destination?.fields?.destinations.forEach((elem) => {
                            if (elem?.fields?.pagePath?.fields?.slug === this.state.pathName) {
                                isActive = true;
                            }
                        })
                    }
                })
            } else {
                if (menuItem?.fields?.pagePath?.fields?.slug === this.state.pathName) {
                    isActive = true;
                }
            }
        }

        return isActive;
    }

    renderMobileHierarchy(hierarchy) {
        const properties = this.getHierarchyProperties(hierarchy);
        const closeMenuLabel = Label({label: this.props.block.getFieldValue(MainMenuConstants.closeMenuLabel)});
        const openMenuLabel = Label({label: this.props.block.getFieldValue(MainMenuConstants.openMenuLabel)});
        const returnToMainMenuLabel = Label({label: this.props.block.getFieldValue(MainMenuConstants.returnToMainMenuLabel)});
       
        let {hierarchyMainLogo} = this.state;
        if(hierarchyMainLogo) {
            hierarchyMainLogo.imgVisible = false;
        }
        return (
            <div className={'ob-main-menu--mobile'}>
                <SlideToggle
                    duration={500}
                    collapsed={properties.isMainMenu && !this.state.isMainMenuOpen}
                    ref={this.openMobileMenuRef}
                    range
                    onExpanding={() => {
                        this.handleToggleMainMenu();
                    }}
                    onCollapsing={() => {
                        this.handleToggleMainMenu();
                    }}
                    onExpanded={() => {
                        this.handleMenuHeight();
                    }}>
                    {({onToggle, setCollapsibleElement, range}) => (
                    <div ref={this.innerMenuRef}
                            className={`${properties.mainMenuClassNames} ${properties.onlyShowOnClassNames} ${properties.subNavigationListClassNames}`}>
                            {properties.isMainMenu &&
                            <div className={'ob-main-menu__logo ob-main-menu__logo--mobile'}>
                                {this.renderDestinationMobileMainLogo(hierarchy?.fields?.destinations[0])}
                            </div>
                            }
                            {properties.isMainMenu &&
                                <div className={'ob-main-menu__open-ctn'}>
                                    <button onClick={onToggle}
                                            className='event_menu_click'
                                            aria-expanded={this.state.isMainMenuOpen}
                                            data-action-detail={this.state.isMainMenuOpen ? closeMenuLabel : openMenuLabel}>
                                        <span>
                                            <Icon name={this.state.isMainMenuOpen ? MainMenuConstants.close : MainMenuConstants.hamburger} size="2" color={this.primaryColor}/>
                                            <span className={'visuallyhidden'}>{this.state.isMainMenuOpen ? closeMenuLabel : openMenuLabel}</span>
                                        </span>
                                    </button>
                                </div>
                            }
                            {properties.title && !properties.destinations && this.renderHierarchyTitle(hierarchy, properties.title)}
                            {properties.destinations && !properties.isSubNav &&
                                <div ref={setCollapsibleElement} className={'ob-menu-collapsible'}>
                                    {properties.title && this.renderHierarchyTitle(hierarchy, properties.title)}
                                    <div ref={properties.isMainMenu && this.mobileMenuRef}>
                                        <ul ref={properties.isSubNavCtn && this.state.currentSubNav === properties.hierarchyId && this.subMenuRef}
                                            id={properties.hierarchyId}
                                            className={`${properties.listClassNames}${this.state.currentSubNav === properties.hierarchyId ? ' is-active' : ''}`}
                                            style={range ? {opacity: Math.max(0, range)} : {opacity: 1}}>
                                            {
                                                this.state.isMainMenuOpen && this.renderDestination(properties, 'chevronRight')
                                            }
                                        </ul>
                                    </div>
                                </div>
                                }                               
                    </div>
                    )}
                </SlideToggle>
            </div>);
    }

    renderDestinationMobile(dest, callback = null, icon = null, onClick, className = null) {
        const type = dest?.fields?.contentType;
        switch (type) {
        case 'pageLink':
            return HierarchyUtils.renderPageLink(dest, icon, onClick, className, true);
        case 'hierarchy':
            dest.fields.level = 'child';        
            return callback ? callback() : this.renderHierarchyMobile(dest);
        case 'link':
            return HierarchyUtils.renderLink(dest, icon, onClick, className, true);
        case 'languageSelectionBlock':
            return <LanguageSelection {...dest}/>;
        case 'loginNavigationBlock':
            return <LoginNavigationBlock document={dest}/>;
        default:
            return 'Unsupported Type : ' + type;
        }
    }

    renderDestinationMobileMainLogo(dest, callback = null, icon = null, onClick, className = null) {
        const type = dest?.fields?.contentType;
        dest.imgVisible = false;
        dest.fields.url = dest?.fields?.pagePath?.fields?.slug
        return type === 'pageLink' ? HierarchyUtils.renderLink(dest) : this.renderDestinationMobile();        
    }
    
    renderDestination(properties, iconName = '') {
        const icon = {
            name: iconName,
            position: properties.iconPosition,
        };

        return properties.destinations.map((dest, index) => {
            dest.imgVisible = true;
            return <li className={`${properties.itemClassNames}${dest?.fields?.className ? ' ' + dest?.fields?.className : ''}${!dest?.fields?.image && !dest?.fields?.mainAsset ? ' no-img' : ' no-img'}${dest?.fields?.contentType === 'loginNavigationBlock' ? ' ob-main-menu__login-navigation' : ''}`}
                key={index} data-is-logo={properties.isMainMenu && index === 0}>
                {this.renderDestinationMobile(dest)}
            </li>
        })
    }

    render() {
        const className = `main-menu ${this.props.block.getFieldValue(MainMenuConstants.classNames) !== undefined ? this.props.block.getFieldValue(MainMenuConstants.classNames) : ''}`;
        const hierarchyDoc = this.props.block.getFieldValue(MainMenuConstants.hierarchy);
        const hierarchyImage = hierarchyDoc.fields.mainAsset;
        const mainMenuLabel = Label({label: this.props.block.getFieldValue(MainMenuConstants.mainMenuLabel)});
       
        return (
            <nav aria-label={mainMenuLabel} ref={this.insideMenuRefMobile} role='navigation' onKeyDown={this.trapFocusInOverlay} className={`${className} ${this.state.isMainMenuOpen ? 'is-active' : ''}`}>
                {this.renderMobileHierarchy(this.props.hierarchy)}
                <Image image={hierarchyImage}></Image>
            </nav>
        )
    }
}

export default MobileTabLayout

MobileTabLayout.propTypes = {
    hierarchy: PropTypes.object,
};
